import React from "react";
import InputMask from "react-input-mask";
import { FormField } from "./Input.Styles";
import { InputLabel } from "./../../../components/Text/Text.Styles";

interface PhoneNumberInputProps {
  value?: string;
  onChange: (e: string) => void;
  placeholder?: string | undefined;
  required?: boolean;
  name?: string;
  isIniAptCmplted?: boolean;
}

const PhoneNumberInput = (props: PhoneNumberInputProps): React.JSX.Element => {
  return (
    <FormField data-testid="phone-input" required={props.required}>
      <InputLabel>Phone Number</InputLabel>
      <InputMask
        value={props.value || ""}
        onChange={(e) => props.onChange(e.target.value)}
        placeholder={props.placeholder || `(___) ___ - ____`}
        mask="(999) 999 - 9999"
        name={props.name}
        disabled={props.isIniAptCmplted}
      />
    </FormField>
  );
};

export default PhoneNumberInput;
