import React from "react";

import { TextStyled } from "components/Text/Text.Styles";
import { CallBadgeDividerStyle } from "components/LeadManagement/IncomingCallInfo/IncomingCallInfoComponent.Styles";
import Select from "components/Form/Select/Select";
import { FlexContainer } from "components/Flex/Flex.Styles";
import { graceGray600 } from "styleConstants";

interface IncomingCallInfoComponentProps {
  callNumber: string;
  firstName?: string;
  lastName?: string;
  customerId?: number;
  subscriptionId?: number;
  emailAddress?: string;
  options?: any;
  value?: string | number;
  placeHolder?: string;
  handleSelectChange: (selected: string) => void;
  addProperty: (selected: string) => void;
}

const CallBadge = (
  props: IncomingCallInfoComponentProps,
): React.JSX.Element => {

  return (
    <>
      {props.callNumber ? (
        <>
          <FlexContainer data-testid="call-badge">
            <TextStyled fontSize="lg" fontWeight="thin">
              {props.firstName && props.lastName
                ? `${props.firstName} ${props.lastName}`
                : "Incoming Call"}
            </TextStyled>
            <CallBadgeDividerStyle>.</CallBadgeDividerStyle> 
            <Select
              options={props.options}
              value={props.value}
              onChange={props.handleSelectChange}
              placeholder={props.placeHolder ? props.placeHolder : "Select an option"}
              isGroupable
            />
          </FlexContainer>
          {props.customerId !== 0 &&
            <>
              <CallBadgeDividerStyle>.</CallBadgeDividerStyle> 
              <TextStyled color={graceGray600} fontWeight="thin" fontSize="md">
                {"Customer ID: "}{props.customerId}
              </TextStyled>
              <CallBadgeDividerStyle>.</CallBadgeDividerStyle>
              <TextStyled color={graceGray600} fontWeight="thin" fontSize="md">
                {"Subscription ID: "}{props.subscriptionId}
              </TextStyled>
            </>
          }                  
        </>
      ) : null}
    </>
  );
};

export default CallBadge;
