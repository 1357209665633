// API constants
export const HOST_NAME = process.env.HOST_NAME || window.location.origin;
export const API_URL = process.env.REACT_APP_API_URL || "";
export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY || "";

export const BASIC_SERVICE_ID = process.env.REACT_APP_BASIC_SERVICE_ID || '1799';
export const PRO_SERVICE_ID = process.env.REACT_APP_PRO_SERVICE_ID || '1800';
export const PREMIUM_SERVICE_ID = process.env.REACT_APP_PREMIUM_SERVICE_ID || '2828';

export const DATA_DOG_ENV = process.env.REACT_APP_DATA_DOG_ENV;

export const ENV = process.env.REACT_APP_ENV;

export const TRACKING_URL =
  process.env.REACT_APP_TRACKING_URL ||
  "https://beta.metrics.goaptivetech.com/a/logger/cxp";

// Permissions constants
export const ADMIN_ROLE = "AppRole-Genesys Cloud-Dev Admin";
export const CLOSER_ROLE = "AppRole-Genesys Cloud-IS-Closer Agent";
export const INBOUND_ROLE = "AppRole-Genesys Cloud-IS-Inbound Setter Agent";
export const OUTBOUND_ROLE = "AppRole-Genesys Cloud-IS-Outbound Setter Agent";
export const TRAINING_ROLE = "AppRole-Genesys Cloud-Training";
export const ACCOUNT_SPECIALIST = "AppRole-Genesys Cloud-IS-Account Specialist";
export const CLOUD_SUPERVISOR = "AppRole-Genesys Cloud-IS-Supervisor";

// Form constants
export const pestsOptions = [
  {
    value: "Ants",
    label: "Ants",
  },
  {
    value: "Spiders",
    label: "Spiders",
  },
  {
    value: "Cockroaches",
    label: "Cockroaches",
  },
  {
    value: "Wasp",
    label: "Wasps",
  },
  {
    value: "TicksFleas",
    label: "Ticks & Fleas",
  },
  {
    value: "YellowJacket",
    label: "Yellow Jackets",
  },
  {
    value: "Earwig",
    label: "Earwigs",
  },
  {
    value: "Cricket",
    label: "Crickets",
  },
  {
    value: "Centipede",
    label: "Centipede",
  },
  {
    value: "Millipede",
    label: "Millipedes",
  },
  {
    value: "Scorpion",
    label: "Scorpions",
  },
  {
    value: "Moths",
    label: "Moths",
  },
  {
    value: "HouseFly",
    label: "House Flies",
  },
  {
    value: "Rodent",
    label: "Rodents",
  },
  {
    value: "Slug",
    label: "Slugs",
  },
  {
    value: "Snail",
    label: "Snails",
  },
  {
    value: "Aphid",
    label: "Aphids",
  },
  {
    value: "Mosquito",
    label: "Mosquitos",
  },
  {
    value: "PantryPests",
    label: "Pantry Pests",
  },
  {
    value: "GermanCockroach",
    label: "German Cockroaches",
  },
];

export const stateOptions = [
  {
    value: "AL",
    label: "Alabama",
  },
  {
    value: "AZ",
    label: "Arizona",
  },
  {
    value: "AR",
    label: "Arkansas",
  },
  {
    value: "CA",
    label: "California",
  },
  {
    value: "CO",
    label: "Colorado",
  },
  {
    value: "CT",
    label: "Connecticut",
  },
  {
    value: "DE",
    label: "Delaware",
  },
  {
    value: "DC",
    label: "District of Columbia",
  },
  {
    value: "FL",
    label: "Florida",
  },
  {
    value: "GA",
    label: "Georgia",
  },
  {
    value: "HI",
    label: "Hawaii",
  },
  {
    value: "ID",
    label: "Idaho",
  },
  {
    value: "IL",
    label: "Illinois",
  },
  {
    value: "IN",
    label: "Indiana",
  },
  {
    value: "IA",
    label: "Iowa",
  },
  {
    value: "KS",
    label: "Kansas",
  },
  {
    value: "KY",
    label: "Kentucky",
  },
  {
    value: "LA",
    label: "Louisiana",
  },
  {
    value: "ME",
    label: "Maine",
  },
  {
    value: "MD",
    label: "Maryland",
  },
  {
    value: "MA",
    label: "Massachusetts",
  },
  {
    value: "MI",
    label: "Michigan",
  },
  {
    value: "MN",
    label: "Minnesota",
  },
  {
    value: "MS",
    label: "Mississippi",
  },
  {
    value: "MO",
    label: "Missouri",
  },
  {
    value: "MT",
    label: "Montana",
  },
  {
    value: "NE",
    label: "Nebraska",
  },
  {
    value: "NV",
    label: "Nevada",
  },
  {
    value: "NH",
    label: "New Hampshire",
  },
  {
    value: "NJ",
    label: "New Jersey",
  },
  {
    value: "NM",
    label: "New Mexico",
  },
  {
    value: "NY",
    label: "New York",
  },
  {
    value: "NC",
    label: "North Carolina",
  },
  {
    value: "ND",
    label: "North Dakota",
  },
  {
    value: "OH",
    label: "Ohio",
  },
  {
    value: "OK",
    label: "Oklahoma",
  },
  {
    value: "OR",
    label: "Oregon",
  },
  {
    value: "PA",
    label: "Pennsylvania",
  },
  {
    value: "RI",
    label: "Rhode Island",
  },
  {
    value: "SC",
    label: "South Carolina",
  },
  {
    value: "SD",
    label: "South Dakota",
  },
  {
    value: "TN",
    label: "Tennessee",
  },
  {
    value: "TX",
    label: "Texas",
  },
  {
    value: "UT",
    label: "Utah",
  },
  {
    value: "VT",
    label: "Vermont",
  },
  {
    value: "VA",
    label: "Virginia",
  },
  {
    value: "WA",
    label: "Washington",
  },
  {
    value: "WV",
    label: "West Virginia",
  },
  {
    value: "WI",
    label: "Wisconsin",
  },
  {
    value: "WY",
    label: "Wyoming",
  },
];

export const propertyOptions = [
  {
    value: 1,
    label: "Apartment"
  },
  {
    value: 2,
    label: "Commercial"
  },
  {
    value: 3,
    label: "Condo"
  },
  {
    value: 4,
    label: "Duplex"
  },
  {
    value: 5,
    label: "Mobile Home"
  },
  {
    value: 6,
    label: "Single Family"
  },
  {
    value: 7,
    label: "Townhome"
  },
];
